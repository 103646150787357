<template>
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 p-2">
    <div class="card box-shadow h-100">
      <a v-bind:href="`/p/${product.id}`" class="nav-link">
        <div class="card-img-container">
          <img v-if="productImageUrl" class="card-img-top img-fluid" :src="productImageUrl" alt="Product Image">
          <img v-else class="card-img-top img-fluid" src="/default-image1.webp" alt="Default Image">
        </div>
        <div class="card-body p-1">
          <p class="m-0 p-0"><b>COD.:&nbsp;{{ product.id_erp }}</b></p>
          <p class="m-0 p-0">{{ product.description }}</p>
          <p class="card-text mb-0" v-if="getLogged">
            <b>R$&nbsp;{{ product.price }}</b>
            <small class="text-muted">{{ product.installment }}</small>
          </p>
          <p class="m-0 p-0"><b>Estoque disponível:&nbsp;{{ Math.round(product.stock_quantity) }}</b></p>
        </div>
      </a>
      <div class="card-footer m-0 p-0">
        <div class="row my-1 mx-1">
          <div class="col-12 col-md-6 mb-2 mb-md-0">
            <a class="btn btn-sm btn-outline-info w-100" v-bind:href="`/p/${product.id}`">
              <i class="fa fa-eye"></i>&nbsp;Ver mais
            </a>
          </div>
          <div class="col-12 col-md-6">
            <a class="btn btn-sm btn-outline-primary w-100" v-bind:href="dynamicHref" target="_blank">
              <i class="fa-brands fa-whatsapp"></i>&nbsp;Saiba mais
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="text-center mb-1">
        <div class="row mx-2">
          <a class="col btn btn-sm bg-secondary text-white" v-bind:href="`/p/${product.id}`">Ver mais</a>
          <a class="col btn btn-sm bg-primary text-white" v-bind:href="dynamicHref" target="_blank">
            <i class="fa-brands fa-whatsapp"></i>&nbsp;Entre em contato</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { linkWhatsApp } from '@/services/contactService';
import { isLogged } from '@/services/tokenService';

export default {
  name: 'ProductCatalog',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    productImageUrl() {
      return this.product.images && this.product.images.length > 0
        ? this.product.images[0].link
        : null;
    },
    dynamicHref() {
      return linkWhatsApp(this.product.id_erp, this.product.short_description);
    },
    getLogged() {
      return isLogged();
    }
  }
};
</script>
<style scoped>
.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* Faz o card ocupar toda a altura disponível */
}

.card-img-container {
  height: 185px;
  /* Mantém uma altura fixa para a imagem */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  /* Ajusta a imagem sem cortar */
}

.card-body {
  flex-grow: 1;
  /* Faz com que o body cresça para ocupar o espaço disponível */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Espaça o conteúdo interno */
  min-height: 150px;
  /* Define uma altura mínima para o card-body */
}

.card-footer {
  margin-top: auto;
  /* Empurra o footer para o final do card */
  background: white;
}

.card-footer .row>div {
  padding-right: 0;
  /* Remove o padding adicional para evitar cortes */
  padding-left: 0;
  /* Remove o padding adicional para evitar cortes */
}

.card-footer .row .btn {
  white-space: normal;
  /* Permite quebra de linha no texto */
  word-wrap: break-word;
  /* Quebra palavras longas se necessário */
}
</style>